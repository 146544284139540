import { GlobalPanelProvider } from '@/hooks/usePanel'
import { signIn, signOut } from 'next-auth/react'
import { useSession } from '@/util/session/index'
import { createContext, useEffect, useRef } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import '@/styles/global.css'
import { ReactQueryDevtools } from 'react-query/devtools'
import { defaultQueryFn } from '@/util/localApi'
import * as ga from '../util/ga'
import { useRouter } from 'next/router'
import NextApp from 'next/app'
import { Inter } from 'next/font/google'
import { Loader } from '@ralston-instruments/app.fieldlab.components.web'

// If loading a variable font, you don't need to specify the font weight
const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter',
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5000,
      refetchOnMount: true,
      queryFn: defaultQueryFn,
      onError: (e) => {
        if (e.request?.status === 401) {
          signOut()
        }
      },
    },
    mutations: {
      onError: (e) => {
        if (e.request?.status === 401) {
          signOut()
        }
      },
    },
  },
})

export const SessionContext = createContext()

function SessionProvider({ children }) {
  const s = useSession()
  return <SessionContext.Provider value={s}>{children}</SessionContext.Provider>
}

// Use the <Provider> to improve performance and allow components that call
// `useSession()` anywhere in your application to access the `session` object.
function App({ Component, pageProps, nonce }) {
  const nonceRef = useRef(nonce)

  useEffect(() => {
    if (nonce) {
      nonceRef.current = nonce
    }
  }, [nonce])

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url)
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // console.log(nonceRef.current)

  return (
    <>
      <style jsx global nonce={nonceRef.current}>{`
        html {
          font-family: ${inter.style.fontFamily};
          --font-inter: ${inter.style.fontFamily};
        }
      `}</style>

      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
        <SessionProvider>
          <GlobalPanelProvider>
            {Component.auth ? (
              <Auth>
                <Component {...pageProps} />
              </Auth>
            ) : (
              <Component {...pageProps} />
            )}
          </GlobalPanelProvider>
        </SessionProvider>
      </QueryClientProvider>
    </>
  )
}

App.getInitialProps = async (context) => {
  const props = NextApp.getInitialProps(context)
  const { ctx } = context
  const nonce = ctx.req?.headers?.['x-nonce'] || ctx.res?.headers?.['x-nonce']

  return {
    ...props,
    nonce,
  }
}

function Auth({ children }) {
  const { data: session, status } = useSession()
  const isUser = !!session?.user
  const error = session?.error

  useEffect(() => {
    if (error === 'RefreshAccessTokenError') {
      signOut() // Force sign in to hopefully resolve error
    }
    if (error === 'LoadUserDataError') {
      // console.log('LoadUserDataError')
      // return <div>LoadUserDataError</div>
      signOut() // Force sign in to hopefully resolve error
    }
    if (status === 'loading') {
      return // Do nothing while loading
    }
    if (!isUser) {
      signIn() // If not authenticated, force log in
    }
  }, [isUser, status, error])

  if (isUser) {
    return children
  }

  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
  return (
    <div className="min-h-full flex flex-col">
      <Loader />
    </div>
  )
}

export default App
