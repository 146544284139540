import { useGetInstance } from '@/util/localApi'
import { getServerSession as realGetServerSession } from 'next-auth'
import session_admin from './session_admin.json'
import { useQuery } from 'react-query'
import { useRouter } from 'next/router'
import { NextAuthOptions } from '@/pages/api/auth/[...nextauth]'

export async function fetchSession() {
  if (process.env.NEXT_PUBLIC_USE_FAKE_AUTH) {
    return session_admin
  }
  const res = await fetch('/api/auth/session')
  const session = await res.json()
  if (Object.keys(session).length) {
    return session
  }
  return null
}

export function useSession({
  required,
  redirectTo = '/api/auth/signin?error=SessionExpired',
  queryConfig = {},
} = {}) {
  const router = useRouter()
  const query = useQuery(['session'], fetchSession, {
    refetchInterval: 1000 * 60 * 5, // 5 minutes in milliseconds
    staleTime: 1000 * 60 * 30, // 30 minutes in milliseconds
    refetchIntervalInBackground: true,
    ...queryConfig,
    onSettled(data, error) {
      if (queryConfig.onSettled) queryConfig.onSettled(data, error)
      if (data || !required) return
      router.push(redirectTo)
    },
  })
  return {
    data: query.data,
    status: query?.data?.user
      ? 'authenticated'
      : query.status === 'loading'
      ? 'loading'
      : 'unauthenticated',
  }
}

// export function useSession(...args) {
//   if (process.env.NEXT_PUBLIC_USE_FAKE_AUTH) {
//     return { status: 'authenticated', data: session_admin }
//   }
//   return realUseSession(...args)
// }

export function useUserInfo() {
  const authSession = useSession()

  return useGetInstance('/api/userinfo', {
    enabled: authSession.status === 'authenticated',
    staleTime: Infinity,
    cacheTime: Infinity,
  })
}

export async function getServerSession(req, res) {
  if (process.env.NEXT_PUBLIC_USE_FAKE_AUTH) {
    return session_admin
  }

  return realGetServerSession(req, res, NextAuthOptions)
}

export function initialSession(sessionProp) {
  if (process.env.NEXT_PUBLIC_USE_FAKE_AUTH) {
    return session_admin
  }

  return sessionProp
}
